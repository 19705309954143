import React from "react";
import DarkTheme from "layouts/Dark";
import NavbarLR from "components/NavbarLR/navbar";
import ArtistsFullScreenCircleSlide from "components/Artists-full-screen-circle-slide/artists-full-screen-circle-slide";

const Showcase3Dark = () => {
  return (
    <DarkTheme>
      <NavbarLR />
      <ArtistsFullScreenCircleSlide />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Artists</title>
    </>
  )
}

export default Showcase3Dark;
