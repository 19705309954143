import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import artists from "data/artists.json";
import SwiperCore, { Navigation, Parallax, Mousewheel } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/mousewheel";

SwiperCore.use([Navigation, Parallax, Mousewheel]);

const ArtistsFullScreenCircleSlide = () => {
  const [load, setLoad] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    });
  }, []);
  const swiperRef = React.useRef(null);

  return (
    <header className="slider circle-slide showcase-carus">
      <div id="content-carousel-container-unq-1" className="swiper-container">
        {!load ? (
          <Swiper
            ref={swiperRef}
            speed={10000}
            mousewheel={true}
            parallax={true}
            centeredSlides={true}
            slidesPerView={1}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            loop={true}
            
            spaceBetween={500}
            className="swiper-wrapper"
            onAfterInit={(swiper) => {
              setTimeout(() => {
                swiper.slideNext(2500);
              }, 2500)
            }}
            onSlideChangeTransitionEnd={(swiper) => {
              setTimeout(() => {
                swiper.slideNext(2500);
              }, 2500)
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].childNodes[0].setAttribute(
                    "data-swiper-parallax",
                    0.75 * swiper.width
                  );
                }
              });
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                spaceBetween: 0,
              },
              640: {
                spaceBetween: 0,
              },
              768: {
                spaceBetween: 30,
              },
              1024: {
                spaceBetween: 500,
              },
            }}
          >
            {artists.map((slide) => (
              <SwiperSlide key={slide.id} className="swiper-slide">
                <div className="full-width">
                  <div
                    className="bg-img valign"
                    style={{ backgroundImage: `url(${slide.image})` }}
                    data-overlay-dark="1"
                  >
                    <div className="caption ontop valign">
                      <div className="o-hidden">
                        <h1 data-swiper-parallax="-2000">
                          <Link to={slide.link.trim() !== '' ? slide.link : '/'} target="blank">
                            <div className="stroke">{slide.title.first}</div>
                            <span>{slide.title.second}</span>
                          </Link>
                        </h1>
                        <h5 data-swiper-parallax="-2000">{slide.description}</h5>
                      </div>
                      <p></p>
                    </div>
                    <div className="copy-cap valign">
                      <div className="cap">
                        <h1 data-swiper-parallax="-2000">
                          <Link to={slide.link.trim() !== '' ? slide.link : '/'} target="blank">
                            <div className="stroke">{slide.title.first}</div>
                            <span>{slide.title.second}</span>
                          </Link>
                        </h1>
                        <h5 data-swiper-parallax="-2000">{slide.description}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </div>

      <div className="txt-botm">
        <div
          onClick={() => swiperRef.current.swiper.slideNext(1000)} 
          className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
        >
          <div>
            <span>Next Slide</span>
          </div>
          <div>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        <div
          onClick={() => swiperRef.current.swiper.slidePrev(1000)} 
          className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
        >
          <div>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div>
            <span>Prev Slide</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ArtistsFullScreenCircleSlide;
